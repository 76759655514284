import { Box, Button, Card, Editable, EditableInput, EditablePreview, Flex, HStack, Spacer, Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { useLocalStore } from "@dladio/hooks"
import { POS_UI_CART_SUMMARY, POS_UI_STOTE } from "../../consts"

export const CartSummary = () => {

    const { model: store, setStore, select } = useLocalStore(POS_UI_STOTE)

    const onClickPrimaryAction = () => {
        setStore('mode', select('mode') == 'edit' ? 'checkout' : 'edit')
    }

    return (
        <Card flex={1} mt={4} variant={'filled'}>
            <Flex direction={'column'} minH={'full'} p={4}>
                <Box>
                    {/* <TableContainer>
                        <Table variant='simple'>
                            <Tbody>
                                <Tr >
                                    <Td py={0} px={0}><Text fontSize='xs'>Total Quantity</Text></Td>
                                    <Td py={0} px={0} isNumeric><Text fontSize='xs'>25.40</Text></Td>
                                </Tr>
                                <Tr>
                                    <Td py={0} px={0}><Text fontSize='xs'>Net Total</Text></Td>
                                    <Td py={0} px={0} pb={4} isNumeric><Text fontSize='xs'>25.40</Text></Td>
                                </Tr>
                                <Tr borderTop={1}>
                                    <Td py={1} px={0}><Text as='b' fontSize={'2xl'}>Grand Total</Text></Td>
                                    <Td py={1} px={0} isNumeric><Text as='b' fontSize={'2xl'}>25.40</Text></Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer> */}
                    <Flex gap={2}>
                        
                    </Flex>
                    <Flex direction={'column'} gap={2}>
                        <Flex w={'full'} justifyContent={'space-between'}>
                            <Text py={0} fontSize='sm' >Total Quantity</Text>
                            <Text py={0} fontSize='sm' as={'b'}>1</Text>
                        </Flex>
                        <Flex w={'full'} justifyContent={'space-between'}>
                            <Text py={0} fontSize='sm' >Net Total</Text>
                            <Text py={0} fontSize='sm' as={'b'}>Rs 0.00</Text>
                        </Flex>
                    </Flex>
                </Box>
                <Spacer flex={1}></Spacer>
                <Flex direction={'column'} gap={2} my={4}>
                    <Flex w={'full'} justifyContent={'space-between'} mt={4}>
                        <Text py={0} fontSize='3xl' as={'b'}>Grand Total</Text>
                        <Text py={0} fontSize='3xl' as={'b'}>Rs 0.00</Text>
                    </Flex>
                </Flex>
                <Box>
                    <Button onClick={onClickPrimaryAction} colorScheme='teal' minW={'full'} variant={'solid'}>
                        {select('mode') == 'edit' ? 'Checkout' : 'Edit Cart'}
                    </Button>
                </Box>
            </Flex>
        </Card>
    )
}