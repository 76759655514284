import { Box, Button, Card, Center, Divider, Flex, Grid, GridItem, Image, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, Stat, StatLabel, StatNumber, Text, useDisclosure } from "@chakra-ui/react"
import { AllItemSelector } from "../components/pos/AllItemSelector"
import { ItemEditor } from "../components/pos/ItemEditor"
import { MainSearchBar } from "../components/pos/MainSearchBar"
import { PosCart } from "../components/pos/PosCart"
import { PosHeader } from "../components/pos/PosHeader"
import { useLocalStore } from "@dladio/hooks"
import { POS_UI_STOTE } from "../consts"
import { useEffect } from "react"

export const PointOfSales = () => {

    const { model: store, setStore, select } = useLocalStore(POS_UI_STOTE)

    useEffect(() => {
        setStore('mode', 'edit')
        setStore('customer', '1')
    }, [])

    return (
        <Box className="flex flex-col h-screen">
            <PosHeader />
            <Body>
                <Box className="flex min-h-full" gap={4}>
                    {select('mode') == 'edit' ? <EditCartUi /> : <OrderCompletionUi />}
                </Box>
            </Body>
        </Box>
    )
}

const Body = ({ children }: any) => {

    return (
        <Box bg={'white'} flex={1} p={4} gap={4}>
            {children}
        </Box>
    )
}

const EditCartUi = () => {

    const itemSearchModelState = useDisclosure()
    const { model: store, setStore, select } = useLocalStore(POS_UI_STOTE)

    const selectedItem = select('selected_item')

    const itemEditMarkup = (
        <>
            <Flex flex={1} gap={4}>
                <AllItemSelector />
                <ItemEditor />
            </Flex>
        </>
    )

    const itemSelectionMarkup = (
        <Card variant={'outline'} flex={1}>
            <Box minH={'full'} maxH={'full'} overflow={'auto'}>
                <Grid className="max-h-1" templateColumns='repeat(5, 1fr)' gap={4} p={4}>
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'}>
                        <Box>
                            {/* image */}
                            <Box p={2}>
                                <Image rounded={'lg'} w={"full"} aspectRatio={1} src='gibbresh.png' fallbackSrc='https://via.placeholder.com/100' />
                            </Box>
                            {/* quantity availabel  */}
                            {/* item name */}
                            <Stack spacing={0} px={2} pb={2}>
                                <Text py={0} fontSize='sm' color={'white'}>English Breakfast</Text>
                                {/* price */}
                                <Text py={0} fontSize='xs' as={'b'} color={'white'}>Rs 0.00</Text>
                            </Stack>
                        </Box>
                    </GridItem>
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                </Grid>
            </Box>
        </Card>
    )

    return (
        <>
            <Flex direction={'column'} w={2 / 3} gap={4}>
                <MainSearchBar state={itemSearchModelState} />
                {selectedItem ? itemEditMarkup : itemSelectionMarkup}
            </Flex>
            <Box w={1 / 3}>
                <PosCart />
            </Box>
        </>
    )
}

const OrderCompletionUi = () => {

    return (
        <>
            <Box w={1 / 3}>
                <PosCart />
            </Box>
            <Flex direction={'column'} w={2 / 3} gap={4}>
                {/* payment method container */}
                <PaymentMethods />
                {/* additional information container */}
                <AdditionalInformation />
                {/* paymemt summary view container */}
                <PaymentSummary />
                {/* complete order button container */}
                <CompleteOrder />
            </Flex>
        </>
    )
}

const PaymentMethods = () => {

    return (
        <Card variant={'outline'} p={4}>
            <Text fontSize='md' mb={2}>Payment Method</Text>
            <Flex className="space-x-4">
                <Card minW={"16"} p={4}>
                    <Flex direction={'column'} gap={4}>
                        {/* payment title */}
                        <Text fontSize='md'>[CASH]</Text>
                        {/* paid amount input field */}
                        <NumberInput defaultValue={15} min={10} max={20}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        {/* selectors */}
                        <Flex gap={4}>
                            <Card flex={1} variant={'outline'} p={2}><Center>100</Center></Card>
                            <Card flex={1} variant={'outline'} p={2}><Center>100</Center></Card>
                            <Card flex={1} variant={'outline'} p={2}><Center>100</Center></Card>
                        </Flex>
                    </Flex>
                </Card>
            </Flex>
        </Card>
    )
}

const AdditionalInformation = () => {

    return (
        <Card variant={'outline'} flex={1} p={4}>
            <Text fontSize='md' mb={2}>Additional Information</Text>
            <Flex flex={1} className="space-x-4">
                <Card variant={'outline'} flex={1}></Card>
                <Divider orientation='vertical' />
                <Card variant={'outline'} flex={1} minH={'full'}>
                    <Center flex={1}>
                        <NumberPad />
                    </Center>
                </Card>
            </Flex>
        </Card>
    )
}

const PaymentSummary = () => {

    return (
        <Card variant={'filled'} p={4}>
            <Flex>
                <Stat>
                    <Center>
                        <StatLabel>Grand Total</StatLabel>
                    </Center>
                    <Center>
                        <StatNumber>[LKR]0.00</StatNumber>
                    </Center>
                </Stat>
                <Divider orientation='vertical' />
                <Stat>
                    <Center>
                        <StatLabel>Paid Total</StatLabel>
                    </Center>
                    <Center>
                        <StatNumber>[LKR]0.00</StatNumber>
                    </Center>
                </Stat>
                <Divider orientation='vertical' />
                <Stat>
                    <Center>
                        <StatLabel>To Be Paid</StatLabel>
                    </Center>
                    <Center>
                        <StatNumber>[LKR]0.00</StatNumber>
                    </Center>
                </Stat>
            </Flex>
        </Card>
    )
}

const CompleteOrder = () => {

    return (
        <Card variant={'filled'} p={4}>
            <Button w={'full'} colorScheme='teal'>Complete Order</Button>
        </Card>
    )
}

const NumberPad = () => {

    const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', '.', '+']

    return (
        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
            {
                keys.map((key: any) => (
                    <GridItem h={10} key={key} aspectRatio={1.5} rounded={'lg'} bg='teal.500'>
                        <Center minH={'full'}>
                            <Text as={'b'} color={"white"}>{key}</Text>
                        </Center>
                    </GridItem>
                ))
            }
        </Grid>
    )
}