const POS_UI_STOTE = 'pos_ui_store'
const POS_UI_CART_SUMMARY = 'pos_ui_store.cart_summary'

const APP_BASE_URL = 'https://coop-staging.dlad.io/api/'

const S_WORKSPCE = 'workspace'

export {
    POS_UI_STOTE,
    POS_UI_CART_SUMMARY,
    APP_BASE_URL,

    S_WORKSPCE
}