
import useLocalStore from "../hooks/use-local-store-hook"
import { Form } from "react-router-dom"

export const FormButton = ({ field }: any) => {

    const [doc, select, setStore] = useLocalStore('doc-view-page.docs[0]')

    return (
       <></>
    )
}