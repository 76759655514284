import { Button } from "@chakra-ui/react"
import { RiArrowLeftSFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom"

export const BackButton = () => {

    const navigate = useNavigate()

    const backBtnActionHandler = () => {
        navigate(-1)
    }

    return (
        <Button onClick={backBtnActionHandler} size={'sm'}>
            <RiArrowLeftSFill fontSize={'lg'} />
        </Button>
    )
}