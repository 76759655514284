
import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import { useForm, useLocalStore } from '@dladio/hooks';
import _ from 'lodash';
import { RiArrowRightCircleLine, RiArrowRightLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

export const LinkField = ({ field }: any) => {

    const navigate = useNavigate()
    const { model, select, setStore } = useLocalStore('doc-view-page')
    const { form, setForm, formController, clearForm } = useForm()
    const ishidden = _.isString(field.depends_on)

    const onLinkClick = () => {
        if (_.get(form, field.fieldname))
            navigate(`/${field.options}/${_.get(form, field.fieldname)}`)
    }

    return (
        <FormControl>
            <FormLabel fontSize={'sm'} fontWeight={'light'}>{field.label}</FormLabel>
            {/* <Input size={'sm'} rounded={'md'} type='email' /> */}
            <InputGroup size={'sm'}>
                <Input size={'sm'} rounded={'md'}
                    name={field.fieldname} onChange={formController} value={_.get(form, field.fieldname) || ''}
                />
                <InputRightElement cursor={'pointer'} onClick={onLinkClick}>
                    <Flex justify={'center'} align={'center'}>
                        <RiArrowRightLine color='teal' />
                    </Flex>
                </InputRightElement>
            </InputGroup>
        </FormControl>
    );
};