import { Box, Button, Card, CardBody, CardHeader, Flex, Spacer, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import { CustomerSearchBar } from "./CustomerSearchBar"
import { CartSummary } from "./CartSmmary"

export const PosCart = () => {

    const customerSearchModelState = useDisclosure()

    return (
        <Flex direction={'column'} className="h-[100%]">
            <CustomerSearchBar state={customerSearchModelState} />
            <Card mt={4} variant={'outline'} >
                <CardHeader py={2}>Item Cart</CardHeader>
                <CardBody minH="400px" maxH="400px" overflowY="auto">
                    <TableContainer>
                        <Table variant='simple' size='sm' >
                            <Thead>
                                <Tr>
                                    <Th>Item</Th>
                                    <Th isNumeric>Qty.</Th>
                                    <Th isNumeric>Amount</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr className="border-b-2">
                                    <Td>
                                        <Stack spacing={1}>
                                            <Text fontSize='sm'>(sm) In love with React & Next</Text>
                                            <Text fontSize='xs'>(xs) In love</Text>
                                        </Stack>
                                    </Td>
                                    <Td isNumeric>1</Td>
                                    <Td isNumeric>1000.00</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </CardBody>
            </Card>

            <CartSummary />
        </Flex>
    )
}