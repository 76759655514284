import { Box, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { useLocalStore } from "@dladio/hooks";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../components/general/PageContainer";
import { S_WORKSPCE } from "../consts";
import { useGetDesktopPageQuery } from "../services/MetaService";

export const HomePage = () => {

    const { select } = useLocalStore(S_WORKSPCE)

    return (
        <PageContainer>
            <WorkspaceGrid page={select('current')} />
        </PageContainer>
    )
}

const WorkspaceGrid = ({ page }: any) => {

    const { data = {}, isLoading, isSuccess } = useGetDesktopPageQuery(page)

    const { message } = data

    const cardsMarkup = message && message?.cards?.items.map((line: any) => (
        <GridLine model={line} />
    ))

    return (
        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
            {cardsMarkup}
        </Grid>
    )
}

const GridLine = ({ model }: any) => {

    const sortedLinks = [...model.links].sort((a: any, b: any) => a.idx - b.idx)

    const linksMarkup = sortedLinks && sortedLinks.map((line: any) => (
        <SubNavLine model={line} />
    ))

    return (
        <GridItem w='100%'>
            <Box bg='gray.100' rounded={'lg'} p={4}>
                <Box>
                    <Text fontSize={'md'} as="b">{model.label}</Text>
                </Box>
                <Flex rounded={'lg'} mt={3} direction={'column'} gap={2}>
                    {linksMarkup}
                </Flex>
            </Box>
        </GridItem>
    )
}

const SubNavLine = ({ model }: any) => {

    const navigate = useNavigate()

    const { link_type, link_to, is_query_report } = model

    const onClick = () => {
        switch (link_type) {
            case 'DocType': {
                navigate(`/${link_to}`)
                break
            }
        }
    }

    return (
        <Flex gap={1} _hover={{ bg: 'gray.400', color: 'white', rounded: 'lg' }} onClick={onClick}>
            <Box w={"8"} aspectRatio={1} border={1} rounded={'md'}>
                <Flex justify={'center'} align={'center'} minH={'full'} border='1px' borderColor='gray.400' rounded={'md'} _hover={{ border: 0 }}>
                    <CircleIcon />
                </Flex>
            </Box>
            <Box flex={1} alignItems={'center'} rounded={'md'} border='1px' borderColor='gray.400' pl={2} cursor={'pointer'}>
                <Flex align={'center'} minH={'full'}>
                    <Text fontSize='sm'>{model.label}</Text>
                </Flex>
            </Box>
        </Flex>
    )
}

const CircleIcon = () => (
    <Icon viewBox='0 0 200 200'>
        <path
            fill='#319795'
            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
        />
    </Icon>
)