import { Box, Card, CardBody, Center, Divider, Flex, Icon, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react"
import { useState } from "react"
import { RiSearchLine } from "react-icons/ri"


export const MainSearchBar = ({ state }: any) => {

    return (
        <Card variant={'outline'}>
            <CardBody>
                <SearchInput state={state} />
            </CardBody>
            <ItemSearchModel state={state} />
        </Card>
    )
}

const SearchInput = ({ state }: any) => {

    const { isOpen, onOpen, onClose } = state

    return (
        <InputGroup>
            <InputLeftElement>
                <Icon as={RiSearchLine} color="gray.500" fontSize="lg" />
            </InputLeftElement>
            <Input
                focusBorderColor="orange.500"
                width="full"
                fontSize="sm"
                variant="filled"
                type="text"
                placeholder="Search by item code, serial number or barcode"
                autoComplete="off"
                onClick={onOpen}
            />
        </InputGroup>
    )
}

const ItemSearchModel = ({ state }: any) => {
    const { isOpen, onOpen, onClose } = state

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} scrollBehavior={'inside'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pb={0}>
                    <Flex minH={'12'} className="space-x-4">
                        <Center>
                            <Icon as={RiSearchLine} color="gray.500" fontSize="30px" textColor={'teal.500'} />
                        </Center>
                        <Input variant='unstyled' placeholder='Search' size='lg' />
                    </Flex>
                    <Divider orientation='horizontal' my={4} />
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <Stack spacing='3'>
                            {[1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,].map((variant) => (
                                <Card key={variant} variant={'filled'} bg={'teal.500'}>
                                    <CardBody>
                                        <Text color={'white'} fontSize='md'>(md) In love with React & Next</Text>
                                        <Text color={'white'} fontSize='xs'>(xs) In love with React & Next</Text>
                                    </CardBody>
                                </Card>
                            ))}
                        </Stack>
                    </Box>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}