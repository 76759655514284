import { Logo } from "./Logo"
import { SearchInput } from "../header/SearchInput"
import { NavAction } from "../header/NavAction"
import { RiClipboardLine } from "react-icons/ri"
import { AiOutlineUser } from "react-icons/ai"
import { NavCategoryMenu } from "../header/NavCategoryMenu"
import { CurrencySelect } from "../header/CurrencySelect"
import { Box, Flex, HStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export const PosHeader = () => {

    const navigate = useNavigate()

    return (
        <Box>

            <Box display={{ base: 'none', lg: 'block' }}>
                <Box px="8" bg={'white'}>
                    <Flex height="4.5rem" align="center" mx="auto">
                        <HStack flex="24rem" spacing="32px">
                            <Logo h="3" />
                            <Box flexShrink={0}>
                                <CurrencySelect />
                            </Box>
                        </HStack>
                        {/* <Box width="full" mx="8">
                            <SearchInput />
                        </Box> */}
                        <HStack spacing="8" flexShrink={0}>
                            <NavAction.Desktop label="[open_orders]" icon={RiClipboardLine} />
                            <NavAction.Desktop label="[username]" icon={AiOutlineUser} />
                            <NavAction.Desktop label="Exit" icon={AiOutlineUser} onClick={() => navigate('/home')}/>
                            {/* <Box position="relative">
                                <NavAction.Desktop label="Cart" icon={RiShoppingCartLine} />
                                <CartCount>1</CartCount>
                            </Box> */}
                        </HStack>
                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}