import { BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APP_BASE_URL } from '../consts'

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['user'],
    baseQuery: fetchBaseQuery({ baseUrl: APP_BASE_URL, credentials: "include" }),

    endpoints: (builder) => ({
        ping: builder.query({
            query: () => `/method/ping`,
        }),
        test: builder.query({
            query: () => `/method/test`,
        }),
        getLoggedUser: builder.query({
            query: () => `method/frappe.auth.get_logged_user`,
            providesTags: ['user']
        }),
        login: builder.mutation<any, any>({
            query: (payload) => ({
                url: `method/login`,
                method: 'POST',
                body: payload,
            }),
            // invalidatesTags: ['User'],
            transformResponse: (response: BaseQueryResult<any>) => {
                if (response.message === "Logged In") return { ...response, isLoggedIn: true }
                else return { ...response, isLoggedIn: false }
            }
        }),
    }),
})

export const {
    usePingQuery,
    useTestQuery,
    useLoginMutation,
    useGetLoggedUserQuery
} = userApi