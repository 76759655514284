import { Box, Button, ButtonGroup, Card, Flex, IconButton, List, ListIcon, ListItem, Menu, MenuButton, MenuItem, MenuList, Spacer, Spinner, Tab, TabList, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useForm, useLocalStore } from "@dladio/hooks";
import { RiAddFill, RiArrowLeftSLine, RiArrowRightSLine, RiFile2Fill, RiMenuFill, RiPrinterFill, RiRefreshLine, RiSaveFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../components/general/BackButton";
import { PageContainer } from "../components/general/PageContainer";
import { SectionedViewLayout } from "../components/general/SectionedViewLayout";
import { TabPanel } from "../components/general/TabPanel";
import { ViewPageFormStatus } from "../components/general/ViewPageFormStatus";
import { ViewPageTitle } from "../components/general/ViewPageTitle";
import { useViewPageDataProvider } from "../components/hooks/useViewPageDataProvider";
import { useViewPageMetaProvider } from "../components/hooks/useViewPageMetaProvider";
import { useEffect } from "react";

export const SampleViewPage = () => {

    const navigate = useNavigate()

    const { model, select, setStore } = useLocalStore('doc-view-page')
    const { form } = useForm()
    const { isLoading } = useViewPageMetaProvider()
    useViewPageDataProvider()
    const isTabbed = select('is_tabbed')

    useEffect(() => {
        setStore('mode', 'edit')
    }, [isLoading])

    const tabbedLayout = (
        <Tabs colorScheme="teal" isLazy>
            <Box
                position="sticky"
                top={0}
                bg="white"
                rounded={'lg'}
                zIndex="sticky"
            >
                <TabList>
                    {select('tab_list') && select('tab_list').map((line: any, index: number) => (
                        <Tab key={index}>{line.label}</Tab>
                    ))}
                </TabList>
            </Box>

            <TabPanels>
                {select('tab_list') && select('tab_list').map((line: any, index: number) => (
                    <TabPanel key={index} line={line} />
                ))}
            </TabPanels>
        </Tabs>
    )

    const pageMarkup = (
        <Box flex={1} overflow={'auto'}>
            {isTabbed ? tabbedLayout : <SectionedViewLayout meta={select('meta[0].fields')} />}
        </Box>
    )

    const loadingMarkup = (
        <Flex flex={1} align={'center'} justify={'center'}>
            <Spinner />
        </Flex>
    )

    return (
        <PageContainer>
            <Flex flex={1} direction={'column'} minH={'full'} gap={4}>
                <Box>
                    <Flex rounded={'lg'} gap={4}>
                        <ButtonGroup variant='outline' spacing='2'>
                            <BackButton />
                        </ButtonGroup>
                        <Flex align={'center'} gap={2}>
                            <ViewPageTitle />
                            <ViewPageFormStatus />
                        </Flex>
                        <Spacer flex={1} />
                        <ButtonGroup variant='outline' spacing='2'>
                            <Button size={'sm'}>
                                <RiArrowLeftSLine />
                            </Button>
                            <Button size={'sm'} mr={4}>
                                <RiArrowRightSLine />
                            </Button>
                            <Button size={'sm'}>
                                <RiRefreshLine />
                            </Button>
                            <Button size={'sm'}>
                                <RiPrinterFill />
                            </Button>
                            <Menu size={'sm'}>
                                <MenuButton
                                    size={'sm'}
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<RiMenuFill />}
                                    variant='outline'
                                />
                                <MenuList>
                                    <MenuItem icon={<RiAddFill />} command='⌘T'>
                                        New Tab
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                            <Button size={'sm'} colorScheme='teal' variant={'solid'} leftIcon={<RiSaveFill />}>Save</Button>
                        </ButtonGroup>
                    </Flex>
                </Box>
                <Flex flex={1} minH={'full'} gap={4}>
                    <Flex bg={"white"} direction={'column'} minH={'full'} w={2 / 12} rounded={'lg'} p={2} border={'1px'} borderColor={'gray.200'}>
                        <Text fontSize='sm' mb={2}>Attchments</Text>
                        <List spacing={2}>
                            {
                                [1, 2, 3].map((line: any) => (
                                    <ListItem key={line}>
                                        <Card p={2} variant={'filled'}>
                                            <Flex>
                                                <Flex align={'center'}>
                                                    <ListIcon as={RiFile2Fill} color='gray.500' />
                                                </Flex>
                                                <Flex flex={1} align={'center'}>
                                                    <Text fontSize={'sm'}>File - {line}</Text>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </ListItem>
                                ))
                            }
                        </List>

                        <Text fontSize='sm' mt={4} mb={2}>Tags</Text>
                        <List spacing={3}>
                            <ListItem>
                                <Card p={2} variant={'filled'}>
                                    <Flex>
                                        <Flex align={'center'}>
                                            <ListIcon as={RiFile2Fill} color='gray.500' />
                                        </Flex>
                                        <Flex flex={1} align={'center'}>
                                            <Text fontSize={'sm'}>Lorem ipsum</Text>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </ListItem>
                        </List>
                    </Flex>
                    <Flex bg={"white"} flex={1} rounded={'lg'} direction={'column'} border={'1px'} borderColor={'gray.200'}>
                        {isLoading ? loadingMarkup : pageMarkup}
                    </Flex>
                </Flex>
            </Flex>
        </PageContainer>
    )
}