const isSectionedLayout = (fields: any) => {
    return fields.find((f: any) => f.fieldtype === "Section Break") ? true : false;
}

const isTabledSection = (fields: any) => {
    return fields.find((f: any) => f.fieldtype === "Table") ? true : false;
}

const getSections = (fields: any) => {
    return fields.filter((f: any) => f.fieldtype === "Section Break" && !f.hidden);
}

const getColumnCount = (fields: any) => {
    return fields.filter((line: any) => line.fieldtype == 'Column Break').length + 1
}

export const useSectionUiGenerator = (_metaData: any) => {

    const isSectiondLayout = isSectionedLayout(_metaData)
    let sections = getSections(_metaData)

    let _sections = []

    if (!isSectiondLayout) {
        _sections.push({
            fields: _metaData,
            isTabled: isTabledSection(_metaData),
            columnCount: getColumnCount(_metaData)
        })

    } else {
        // there can be initial sections without section break at the begning

        if(sections[0].idx > 1) {
            let line = {
                idx: 0
            }

            sections.unshift(line);
        }

        for (let i = 0; i <= sections.length; i++) {
            let line = sections[i];

            _sections.push(Object.assign({}, line))

            if (line) {
                const isLastSection = i == (sections.length - 1);

                if (isLastSection) {
                    const fields = _metaData.filter((field: any) => field.idx > line.idx)
                    _sections[i].fields = fields
                    _sections[i].isTabled = isTabledSection(fields)

                } else {
                    const nextSection = sections[i + 1];
                    const endIdx = nextSection.idx
                    const fields = _metaData.filter((field: any) => field.idx > line.idx && field.idx < endIdx && !field.hidden)

                    const columnCount = getColumnCount(fields)

                    _sections[i].fields = fields
                    _sections[i].isTabled = isTabledSection(fields)
                    _sections[i].columnCount = columnCount
                }
            }
        }
    }


    return {
        sectionsCount: 0,
        isSectioned: isSectiondLayout,
        sections: _sections
    }
}