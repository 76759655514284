
import { FormControl, FormLabel } from "@chakra-ui/react"
import { Select as CSelect } from '@chakra-ui/react'
import useLocalStore from "../hooks/use-local-store-hook"

export const Select = ({ field }: any) => {

    const [doc, select, setStore] = useLocalStore('doc-view-page.docs[0]')
    const options = field.options ? field.options.split(/\r?\n/) : []

    const onSelect = (e: any, value: any) => {
        setStore(field.fieldname, value.optionValue)
    }

    const optionsMarkup = options && options.map((line: any, index: number) => (
        <option key={index} value={line}>{line}</option>
    ))

    return (
        <FormControl>
            <FormLabel fontSize={'sm'} fontWeight={'light'}>{field.label}</FormLabel>
            <CSelect size={'sm'} rounded={'md'} placeholder='Select option'>
                {optionsMarkup}
            </CSelect>
        </FormControl>
    )
}