import { useForm, useLocalStore } from "@dladio/hooks"
import { useEffect, useState } from "react"
import { useGetDocQuery, useGetDocTypeQuery } from "../../services/MetaService"
import { useParams } from "react-router-dom";

export const useViewPageDataProvider = () => {

    const { doctype, name } = useParams();
    const [isLoading, setIsLoading] = useState(true)

    const dataRequest = useGetDocQuery({ doctype, name })

    const { model: store, select, setStore } = useLocalStore('doc-view-page')
    const { setForm } = useForm()

    useEffect(() => {
        setIsLoading(true)
    }, [doctype, name])

    useEffect(() => {
        if (dataRequest.isSuccess) {
            let docs = select('docs')
            if (docs && docs.length > 0) {

                const storeModifiedTime = docs[0].modified
                const rtkModifiedTime = dataRequest.data.docs[0].modified

                if (rtkModifiedTime == storeModifiedTime) {
                    return
                }
            }

            setForm(dataRequest.data.docs[0])
        }

    }, [dataRequest])

    const setIsLoadingState = () => {
        if (dataRequest.isSuccess) setIsLoading(false)
        else setIsLoading(true)
    }

    useEffect(setIsLoadingState, [dataRequest])

    useEffect(() => {
        console.log('isLoading -- ', isLoading)
    }, [isLoading])

    return {
        isLoading,
    }
}