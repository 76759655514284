import { Box, Card, CardBody, Center, CloseButton, Divider, Flex, Icon, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Spacer, Stack, Text } from "@chakra-ui/react"
import { useLocalStore } from "@dladio/hooks"
import { RiSearchLine } from "react-icons/ri"
import { POS_UI_STOTE } from "../../consts"

export const CustomerSearchBar = ({ state }: any) => {

    const { model: store, setStore, select } = useLocalStore(POS_UI_STOTE)
    const customer = select('customer')

    const onClearClick = () => {
        setStore('customer', null)
    }

    const searchMarkup = (
        <Card variant={'outline'}>
            <CardBody>
                <SearchInput state={state} />
            </CardBody>
            <CustomerSearchModel state={state} />
        </Card>
    )

    const displayMarkup = (
        <Card variant={'outline'}>
            <CardBody>
                <Flex gap={4}>
                    {/* image container */}
                    <Box>
                        <Image h={41} borderRadius='full' src='gibbresh.png' fallbackSrc='https://via.placeholder.com/100' />
                    </Box>
                    {/* customer details */}
                    <Stack spacing={0}>
                        <Text fontSize='lg'>(lg) In love with React & Next</Text>
                        <Text fontSize='xs'>(xs) In love with React & Next</Text>
                    </Stack>
                    <Spacer flex={1}></Spacer>
                    {/* clear customer */}
                    <Box>
                        <Center minH={'full'}>
                            <CloseButton onClick={onClearClick} size='md' />
                        </Center>
                    </Box>
                </Flex>
            </CardBody>
        </Card>
    )

    return customer ? displayMarkup : searchMarkup
}

const SearchInput = ({ state }: any) => {

    const { isOpen, onOpen, onClose } = state

    return (
        <InputGroup>
            <InputLeftElement>
                <Icon as={RiSearchLine} color="gray.500" fontSize="lg" />
            </InputLeftElement>
            <Input
                focusBorderColor="orange.500"
                width="full"
                fontSize="sm"
                variant="filled"
                type="text"
                placeholder="Search by customer name, id"
                autoComplete="off"
                onClick={onOpen}
            />
        </InputGroup>
    )
}

const CustomerSearchModel = ({ state }: any) => {
    const { isOpen, onOpen, onClose } = state

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pb={0}>
                    <Flex minH={'12'} className="space-x-4">
                        <Center>
                            <Icon as={RiSearchLine} color="gray.500" fontSize="30px" textColor={'teal.500'} />
                        </Center>
                        <Input variant='unstyled' placeholder='Search' size='lg' />
                    </Flex>
                    <Divider orientation='horizontal' my={4} />
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <Stack spacing='3'>
                            {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((variant) => (
                                <Card key={variant} variant={'filled'} bg={'teal.500'}>
                                    <CardBody>
                                        <Text color={'white'} fontSize='md'>(md) In love with React & Next</Text>
                                        <Text color={'white'} fontSize='xs'>(xs) In love with React & Next</Text>
                                    </CardBody>
                                </Card>
                            ))}
                        </Stack>
                    </Box>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}