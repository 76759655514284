import { combineReducers } from "redux";
import { userApi } from "../../services/UserService";
import { metaApi } from "../../services/MetaService";
import { formStore, localStore } from "@dladio/hooks";

const rootReducer = combineReducers({
    localStore: localStore,
    formStore: formStore,
    [userApi.reducerPath]: userApi.reducer,
    [metaApi.reducerPath]: metaApi.reducer,
})

export default rootReducer