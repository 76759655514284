import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/root-reducer";
import { userApi } from "../services/UserService";
import { metaApi } from "../services/MetaService";

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        // getDefaultMiddleware().concat(usersApi.middleware);
        return getDefaultMiddleware({
            serializableCheck: false
        })
        .concat(userApi.middleware)
        .concat(metaApi.middleware)
        // .concat(usersApi.middleware)
        // .concat(msGraphApi.middleware)
        // .concat(formBuilderMiddleware)
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootStore = ReturnType<typeof store.getState>;
