import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"

export const PageContainer = ({ children }: any) => {

    return (
        <Flex direction={'column'} minH={'full'} maxH={'0'} px={8} py={4} overflow={'auto'}>
            {/* <Breadcrumb spacing='8px' separator="-" pb={4}>
                <BreadcrumbItem>
                    <BreadcrumbLink href='#'>Home</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href='#'>[page name]</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb> */}
            {children}
        </Flex>
    )
}