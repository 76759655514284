import { FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APP_BASE_URL } from '../consts'


export const metaApi = createApi({
    reducerPath: 'metaApi',
    baseQuery: fetchBaseQuery({ baseUrl: APP_BASE_URL, credentials: "include" }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        getWorkspaces: builder.query({
            query: ({ doctype }: any) => `method/frappe.desk.desktop.get_workspace_sidebar_items`,
        }),
        getDesktopPage: builder.query({
            // query: ({ doctype }: any) => `method/frappe.desk.desktop.get_desktop_page`,
            query: (page: any): FetchArgs => {
                page = { name: page.name }
                const body: any = { page: JSON.stringify(page) }
                let form = ''

                for (let key in body) {
                    form += `${key}=${body[key]}&`
                }

                form = form.slice(0, -1)

                return {
                    url: `method/frappe.desk.desktop.get_desktop_page`,
                    method: 'POST',
                    body: encodeURI(form),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }
            }
        }),
        getDocTypeMeta: builder.query({
            query: ({ doctype }: any) => `method/frappe.desk.form.load.getdoctype?doctype=${doctype}`,
        }),
        getListData: builder.query({
            query: ({ doctype, fields, start = 0, pageLength = 20 }: any): FetchArgs => {

                let form = ''

                const body: any = {
                    doctype: doctype,
                    fields: fields ? `[${fields}]` : `[]`,
                    page_length: pageLength,
                    order_by: `\`tab${doctype}\`.\`modified\` desc`,
                    start: start,
                    view: 'List'
                }

                for (let key in body) {
                    form += `${key}=${body[key]}&`
                }

                form = form.slice(0, -1)

                return {
                    url: `method/frappe.desk.reportview.get`,
                    method: 'POST',
                    body: encodeURI(form),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }
            },
        }),
        getDocType: builder.query({
            query: ({ doctype, with_parent = 1, cached_timestamp = '' }: any): FetchArgs => {

                return {
                    url: `method/frappe.desk.form.load.getdoctype`,
                    params: {
                        doctype,
                        with_parent
                    }
                }
            }
        }),
        getDoc: builder.query({
            query: ({ doctype, name }: any): FetchArgs => {

                return {
                    url: `method/frappe.desk.form.load.getdoc`,
                    params: {
                        doctype,
                        name
                    }
                }
            },
            providesTags: ['Post'],
        }),
        saveDoc: builder.mutation<any, any>({
            invalidatesTags: ['Post'],
            query: (payload): FetchArgs => ({
                url: `method/frappe.desk.form.save.savedocs`,
                method: 'POST',
                body: encodeURI(payload),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
            }
            ),
        }),
        searchLink: builder.query({
            query: ({ txt, doctype, reference_doctype }: any): FetchArgs => {
                const body: any = {
                    txt,
                    doctype,
                    ignore_user_permissions: 0,
                    reference_doctype
                }
                let form = ''

                for (let key in body) {
                    form += `${key}=${body[key]}&`
                }

                form = form.slice(0, -1)

                return {
                    url: `method/frappe.desk.search.search_link`,
                    method: 'POST',
                    body: encodeURI(form),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }
            }
        })
    }),
})

export const {
    useGetDocTypeMetaQuery,
    useGetListDataQuery,
    useGetDocTypeQuery,
    useGetDocQuery,
    useSaveDocMutation,
    useSearchLinkQuery,
    useGetWorkspacesQuery,
    useGetDesktopPageQuery
} = metaApi