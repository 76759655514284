import {
    Checkbox,
    FormControl
} from '@chakra-ui/react';
import { useForm } from '@dladio/hooks';
import _ from 'lodash';

export const Check = ({ field }: any) => {

    const { form, setForm, formController, clearForm } = useForm()
    const ishidden = _.isString(field.depends_on)

    const handleChange = () => {
        setForm(field.fieldname, _.get(form, field.fieldname) == 0 ? 1 : 0)
    };

    return (
        <FormControl hidden={ishidden}>
            <Checkbox size={'sm'} rounded={'md'} fontWeight={'light'}
                colorScheme={'teal'}
                name={field.fieldname} onChange={handleChange} isChecked={_.get(form, field.fieldname)}
            >{field.label}</Checkbox>
        </FormControl>
    )
}



