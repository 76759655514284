import { Box, Card, CardHeader, Divider, Flex, Grid, Heading } from "@chakra-ui/react"
import { useLocalStore } from "@dladio/hooks"
import { FormElementGenerator } from "../form-components/FormElementGenerator"
import { useSectionUiGenerator } from "../hooks/useSectionUiGenerator"

export const SectionedViewLayout = ({ meta }: any) => {

    const { model, select, setStore } = useLocalStore('doc-view-page')
    const { sections } = useSectionUiGenerator(meta)

    const sectionsMarkup = sections
        .filter((line: any, index: number) => line.fields && line.fields.length > 0)
        .map((line: any, index: number) => {
            return (
                <Box key={index}>
                    <Card p={4} variant={'unstyled'} bg={'transparent'} rounded={'lg'}>
                        <CardHeader p={0} pb={4}>
                            <Heading size='sm'>{line.label}</Heading>
                        </CardHeader>
                        <Section {...line}></Section>
                    </Card>
                    <Divider py={4}></Divider>
                </Box>
            )
        })

    return (
        <Flex direction={'column'} gap={4}>
            {sectionsMarkup}
        </Flex>
    )
}

const Section = ({ fields, columnCount, isTabled }: any) => {

    let fieldsMarkup = <></>
    let currentColumn = 0
    let columns: any = []

    if (columnCount == 1) {
        const _fields = fields && fields.map((field: any, index: number) => (
            <FormElementGenerator key={field.fieldname} field={field} />
        ))

        fieldsMarkup = (
            <Flex flexDirection={'column'} gap={4} w={'full'}>
                {_fields}
            </Flex>
        )
    } else {
        for (let field of fields) {
            if (field.fieldtype == 'Column Break') {
                currentColumn++
                continue;
            }

            if (!columns[currentColumn]) columns[currentColumn] = []

            columns[currentColumn].push(field)
        }

        fieldsMarkup = columns.map((column: any, index: number) => (
            <Flex direction={'column'} rowGap={4} key={index} flex={1}>
                {column.map((field: any) => (<FormElementGenerator key={field.fieldname} field={field} />))}
            </Flex>
        ))
    }

    const columnCountGapMap: any = {
        1: "",
        2: "12",
        3: "12",
        4: "12"
    }

    const flexWidth = isTabled ? 'full' : (columnCount > 1 ? 'full' : '50%')

    return (
        <Box>
            <Flex
                w={flexWidth}
                columnGap={columnCountGapMap[columnCount]}
            >
                {fieldsMarkup}
            </Flex>
        </Box>
    )
}