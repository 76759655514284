import { Text } from "@chakra-ui/react"
import { useForm, useLocalStore } from "@dladio/hooks"

export const ViewPageTitle = () => {

    const { select } = useLocalStore('doc-view-page')
    const { form } = useForm()

    return (
        <Text as='b'>{form ? (form[select('meta[0].title_field')] || form.name) : 'Loading...'}</Text>
    )
}