import { Box, Flex, HStack } from "@chakra-ui/react";
import { AiOutlineUser } from "react-icons/ai";
import { RiNotification4Line } from "react-icons/ri";
import { CartCount } from "../header/CartCount";
import { Logo } from "../header/Logo";
import { NavAction } from "../header/NavAction";
import { NavCategoryMenu } from "../header/NavCategoryMenu";
import { SearchInput } from "../header/SearchInput";
import { useGetLoggedUserQuery } from "../../services/UserService";

export const Header = ({ hideNav = false }) => {

    const { data, isLoading } = useGetLoggedUserQuery({})

    return (
        <Box>
            <Box display={{ base: 'none', lg: 'block' }}>
                <Box px="8" bg={'white'} borderBottom={hideNav ? '1px' : 'none'} borderColor={'gray.200'}>
                    <Flex height="4.5rem" align="center" mx="auto">
                        <HStack flex="24rem" spacing="32px">
                            <Logo h="3" />
                        </HStack>
                        <Box width="full" mx="8">
                            <SearchInput />
                        </Box>
                        <HStack spacing="8" flexShrink={0}>
                            <Box position="relative">
                                <NavAction.Desktop label="Notifications" icon={RiNotification4Line} />
                                <CartCount>1</CartCount>
                            </Box>
                            <NavAction.Desktop label={data?.message} icon={AiOutlineUser} />
                        </HStack>
                    </Flex>
                </Box>
                {!hideNav && <NavCategoryMenu.Desktop />}
            </Box>
        </Box>
    )
}