
import { FormControl, FormLabel, Textarea as CTextarea } from "@chakra-ui/react";
import useLocalStore from "../hooks/use-local-store-hook";

export const Textarea = ({ field }: any) => {

    const [doc, select, setStore] = useLocalStore('doc-view-page.docs[0]')

    return (
        <FormControl>
            <FormLabel fontSize={'sm'}>{field?.label}</FormLabel>
            <CTextarea size={'sm'} rounded={'md'} />
        </FormControl>
    )
}