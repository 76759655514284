
import useLocalStore from "../hooks/use-local-store-hook"

export const Percent = ({ field }: any) => {

    const [doc, select, setStore] = useLocalStore('doc-view-page.docs[0]')

    return (
       <></>
    )
}