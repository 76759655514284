import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { setModel } from "../../stores/reducers/local-store";
import { RootStore } from "../../stores/store";

const useLocalStore = (path: string) => {
    const dispatch = useDispatch()
    const model = useSelector<RootStore, any>((state: any) => _.get(state.localStore.model, path))

    const set = (...arg: any[]) => {
        if (arg.length < 2) {
            dispatch(setModel({ path: path, model: arg[0] }))
        } else if (arg.length == 2) {
            dispatch(setModel({ path: path + '.' + arg[0], model: arg[1] }))
        }
    }

    const get = (...arg: any[]) => {
        if (arg.length < 2) {
            return _.get(model, arg[0])
        } else if (arg.length == 2) {
            let partialModel = _.get(model, arg[0])
            return (key: string) => _.get(partialModel, key)
        }
    }

    return [model, get, set]
}

export default useLocalStore
