import { Check } from "./Check";
import { ChildTable } from "./ChildTable";
import { Color } from "./Color";
import { Currency } from "./Currency";
import { Datafield } from "./Datafield";
import { Date } from "./Date";
import { Float } from "./Float";
import { FormButton } from "./FormButton";
import { Int } from "./Int";
import { LinkField } from "./LinkField";
import { LongText } from "./LongText";
import { Percent } from "./Percent";
import { SectionBreak } from "./SectionBreak";
import { Select } from "./Select";
import { Signature } from "./Signature";
import { SmallText } from "./SmallText";
import { TextEditor } from "./TextEditor";
import { Textarea } from "./Textarea";
import { Time } from "./Time";

export const FormElementGenerator = ({ field }: any) => {

    let markup

    switch (field.fieldtype) {
        case 'Data': markup = <Datafield field={field} />; break;
        case 'Link': markup = <LinkField field={field} />; break;
        case 'Select': markup = <Select field={field} />; break;
        case 'Date': markup = <Date field={field} />; break;
        case 'Text': markup = <Textarea field={field} />; break;
        case 'Text Editor': markup = <TextEditor field={field} />; break;
        case 'Table': markup = <ChildTable field={field} />; break;
        case 'Check': markup = <Check field={field} />; break;
        case 'Signature': markup = <Signature field={field} />; break;
        case 'Section Break': markup = <SectionBreak field={field} />; break;
        case 'Small Text': markup = <SmallText field={field} />; break;
        case 'Float': markup = <Float field={field} />; break;
        case 'Int': markup = <Int field={field} />; break;
        case 'Currency': markup = <Currency field={field} />; break;
        case 'Percent': markup = <Percent field={field} />; break;
        case 'Button': markup = <FormButton field={field} />; break;
        case 'Time': markup = <Time field={field} />; break;
        case 'Long Text': markup = <LongText field={field} />; break;
        case 'Color': markup = <Color field={field} />; break;
        // case 'Column Break': markup = <></>; break;
        default: markup = <>{field.fieldtype}</>
    }

    return markup
}