import { useForm, useLocalStore } from "@dladio/hooks"
import { useEffect, useState } from "react"
import { useGetDocQuery, useGetDocTypeQuery } from "../../services/MetaService"
import { useParams } from "react-router-dom";

export const useViewPageMetaProvider = () => {

    const { doctype, name } = useParams();
    const [isLoading, setIsLoading] = useState(true)

    const metaRequest = useGetDocTypeQuery({ doctype })

    const { model: store, select, setStore } = useLocalStore('doc-view-page')
    const { setForm } = useForm()

    useEffect(() => {
        setIsLoading(true)
    }, [doctype, name])

    useEffect(() => {
        if (metaRequest.isSuccess) {
            let docfieldMap: any = {}

            for (let dt of metaRequest.data.docs) {
                docfieldMap[dt.name] = {}

                for (let df of dt.fields) {
                    docfieldMap[df.parent][df.fieldname] = df
                }
            }

            setStore('meta', metaRequest.data.docs)
            setStore('docfield_map', docfieldMap)
        }
    }, [metaRequest])

    const setIsLoadingState = () => {
        if (metaRequest.isSuccess) setIsLoading(false)
        else setIsLoading(true)
    }

    useEffect(setIsLoadingState, [metaRequest])

    const is_tabbed_layout = (fields: any) => {
        return fields.find((f: any) => f.fieldtype === "Tab Break") ? true : false;
    }

    const get_tabs = (fields: any) => {
        return fields.filter((f: any) => f.fieldtype === "Tab Break")
    }

    const get_doctype_fields = () => {
        if (doctype && select('docfield_map')) {
            return sort_docfields(select('docfield_map')[doctype])
        }
        else return []
    }

    const sort_docfields = (docfieldMap: any) => {
        let fieldList = []
        for (let key in docfieldMap) {
            fieldList.push(docfieldMap[key])
        }

        fieldList = fieldList.sort((a: any, b: any) => {
            return a.idx - b.idx;
        });

        return fieldList
    }

    const determineUiType: any = () => {
        let fields = get_doctype_fields()
        if (fields && fields.length > 0) {
            let isTabbedLayout = is_tabbed_layout(fields)

            if (isTabbedLayout) {
                let tabs: any[] = get_tabs(fields)

                if (tabs[0].idx > 1) { // the layout has no initial tab configured
                    tabs.splice(0, 0, {
                        fieldtype: "Tab Break",
                        label: 'Details',
                        fieldname: 'Details'
                    })
                }

                setStore('is_tabbed', true)
                setStore('tab_list', tabs)

                let tab_fields: any = {}
                for (let index = 0; index < tabs.length; index++) {
                    let startIndex = tabs[index].idx
                    let endIndex = index < tabs.length - 1 ? (tabs[index + 1].idx) - 1 : fields.length

                    let fieldsInTab = fields.slice(startIndex, endIndex)

                    tab_fields[tabs[index].fieldname] = fieldsInTab
                }

                setStore('tab_fields', tab_fields)
            } else {
                setStore('is_tabbed', false)
            }

        } else {
            setStore('is_tabbed', false)
        }
    }

    useEffect(determineUiType, [metaRequest, isLoading, doctype, name, select('meta')])

    return {
        isLoading,
    }
}