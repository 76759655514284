import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import _ from 'lodash'
import { store } from '../store'

interface ISetModelPayload {
    model: any,
    path: string
}

interface IRemovePayload {
    path: string
}

const initialState = {
    model: {}
}

const slice = createSlice({
    name: 'local-store',
    initialState,
    reducers: {
        setModel(state, {payload}: PayloadAction<ISetModelPayload>) {
            state.model = _.set(state.model, payload.path, payload.model)
        },
        remove(state, {payload}: PayloadAction<IRemovePayload>) {
            state.model = _.unset(state.model, payload.path)
        }
    },
})

export const {setModel, remove} = slice.actions
export default slice.reducer