
import { FormControl, FormLabel, Textarea as CTextarea } from "@chakra-ui/react";
import useLocalStore from "../hooks/use-local-store-hook";
import { useForm } from "@dladio/hooks";
import _ from "lodash";

export const TextEditor = ({ field }: any) => {

    const { form, setForm, formController, clearForm } = useForm()
    const ishidden = _.isString(field.depends_on)

    return (
        <FormControl>
            <FormLabel fontSize={'sm'} fontWeight={'light'}>{field.label} -- TODO(TextEditor)</FormLabel>
            <CTextarea size={'sm'} rounded={'md'}
                name={field.fieldname} onChange={formController} value={_.get(form, field.fieldname)}
            />
        </FormControl>
    )
}