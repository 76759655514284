import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Header } from "../general/Header";
import { RootUi } from "./RootUi";

export const InternalPageLayout = () => {

    return (
        <RootUi>
            <Header hideNav/>
            <Body>
                <Outlet />
            </Body>
        </RootUi>
    )
}

const Body = ({ children }: any) => {

    return (
        <Box bg={'gray.50'} flex={1}>
            {children}
        </Box>
    )
}