import { Box, Button, ButtonGroup, Center, Divider, Flex, HStack, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Select, Spacer, Spinner, Stack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { RiAddFill, RiArrowLeftSLine, RiArrowRightSLine, RiFilterLine, RiFilterOffLine, RiMenuFill, RiRefreshLine, RiSortAsc } from "react-icons/ri"
import { PageContainer } from "../components/general/PageContainer"
import { useNavigate, useParams } from "react-router-dom"
import { useGetDocTypeMetaQuery, useGetListDataQuery } from "../services/MetaService"
import { useEffect, useState } from "react"
import { useLocalStore } from "@dladio/hooks"
import { STORE } from "../stores/actions/general-list-page-actions"
import _ from "lodash"
import { BackButton } from "../components/general/BackButton"

export const SampleListPage = () => {

    const { doctype } = useParams();
    const [pageLength, setPageLength] = useState(20)
    // const [start, setStart] = useState(0)
    const [page, setPage] = useState(0)
    const navigate = useNavigate()
    const { model, select, setStore } = useLocalStore(STORE)

    const docTypeMetaData = useGetDocTypeMetaQuery({ doctype })
    const { data, isError, isLoading, isSuccess, isFetching, refetch } = useGetListDataQuery({
        doctype,
        pageLength,
        start: (page * pageLength),
        fields: select('columns')?.map((line: any) => `"\`tab${doctype}\`.\`${line.fieldname}\`"`)
    })

    const onClick = (name: any) => {
        navigate(`/${doctype}/${name}`)
    }

    const onAddBtnClick = () => {
        navigate(`/${doctype}/new`)
    }

    const onRefreshBtnClicked = () => {
        refetch()
    }

    const loadNextPageBtnClicked = () => {
        setPage(page + 1)
        // setStart((page * pageLength) + pageLength)
    }

    const loadPreviousPageBtnClicked = () => {
        if (page == 0) return

        setPage(page - 1)
        // setStart((page * pageLength) + pageLength)
    }

    const setPageLenght = (value: any) => {
        setPageLength(value)
    }

    useEffect(() => {
        if (isSuccess) {
            setStore('data', data.message)
        }
    }, [data, isLoading])

    useEffect(() => {
        if (docTypeMetaData.isSuccess) {
            const columns = docTypeMetaData.data.docs[0].fields
                .filter((line: any) => line.in_list_view)
            columns.splice(0, 0, { fieldname: 'name', label: 'Name' })

            setStore('columns', columns)
            setStore('meta', docTypeMetaData.data.docs[0])
        }

        return () => {
            setStore({})
        }
    }, [docTypeMetaData.data, docTypeMetaData.isLoading])

    return (
        <PageContainer>
            <Flex flex={1} direction={'column'} minH={'full'} gap={4}>
                <Box>
                    <Flex rounded={'lg'} gap={4}>
                        <ButtonGroup variant='outline' spacing='2'>
                            <BackButton />
                        </ButtonGroup>
                        <Flex align={'center'}>
                            <Text as='b'>{doctype}</Text>
                        </Flex>
                        <Spacer flex={1} />
                        <ButtonGroup variant='outline' spacing='2'>
                            <Button onClick={onRefreshBtnClicked} isLoading={isFetching} size={'sm'}>
                                <RiRefreshLine />
                            </Button>
                            <Menu size={'sm'}>
                                <MenuButton
                                    size={'sm'}
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<RiMenuFill />}
                                    variant='outline'
                                />
                                <MenuList>
                                    <MenuItem icon={<RiAddFill />} command='⌘T'>
                                        New Tab
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                            <Button onClick={onAddBtnClick} size={'sm'} colorScheme='teal' variant={'solid'} leftIcon={<RiAddFill />}>Add {doctype}</Button>
                        </ButtonGroup>
                    </Flex>
                </Box>
                <Flex flex={1} minH={'full'} gap={4}>
                    <Flex bg={"white"} direction={'column'} minH={'full'} w={2 / 12} rounded={'lg'} border={'1px'} borderColor={'gray.200'} p={2}>
                        <Text fontSize='sm' mb={4}>Filters</Text>
                        <Stack spacing={2}>
                            <Select rounded={'md'} size={'sm'} placeholder='Assigned to'>
                                {/* <option>United Arab Emirates</option>
                                <option>Nigeria</option> */}
                            </Select>
                            <Select rounded={'md'} size={'sm'} placeholder='Created by'>

                            </Select>
                            <Button size={'sm'} colorScheme='teal' variant='link'>
                                Edit Filters
                            </Button>
                            <Select mt={4} rounded={'md'} size={'sm'} placeholder='Tags'>

                            </Select>
                            <Button size={'sm'} colorScheme='teal' variant='link'>
                                Show Tags
                            </Button>
                        </Stack>
                    </Flex>
                    <Flex bg={"white"} flex={1} rounded={'lg'} direction={'column'} border={'1px'} borderColor={'gray.200'}>
                        {/* header */}
                        <ListViewFilterPanel />

                        <Divider />

                        <Flex mt={2} flex={1} overflow="auto">
                            {
                                (!isLoading && !docTypeMetaData.isLoading)
                                    ?
                                    <DataTable columns={select('columns') || []} data={select('data') || []} onLinkClick={onClick} />
                                    :
                                    <Flex flex={1} justify={'center'} align={'center'} pt={4}>
                                        <Spinner />
                                    </Flex>
                            }
                            {/* <DataTable columns={select('columns') || []} data={select('data') || []} onLinkClick={onClick} /> */}
                        </Flex>

                        <Divider />
                        <Flex p={2}>
                            <ButtonGroup size='sm' isAttached variant='outline'>
                                <Button isActive={pageLength == 20} onClick={() => setPageLenght(20)}>20</Button>
                                <Button isActive={pageLength == 100} onClick={() => setPageLenght(100)}>100</Button>
                                <Button isActive={pageLength == 500} onClick={() => setPageLenght(500)}>500</Button>
                            </ButtonGroup>
                            <Spacer />
                            <ButtonGroup variant='outline' spacing='2'>
                                <Button disabled={isFetching} onClick={loadPreviousPageBtnClicked} size={'sm'}>
                                    <RiArrowLeftSLine />
                                </Button>
                                <Flex align={'center'} justify={'center'} aspectRatio={1} minH={'full'} border={'1px'} borderColor={'gray.200'} rounded={'lg'}>
                                    <Text fontSize='xs'>{page + 1}</Text>
                                </Flex>
                                <Button disabled={isFetching} onClick={loadNextPageBtnClicked} size={'sm'}>
                                    <RiArrowRightSLine />
                                </Button>
                            </ButtonGroup>

                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </PageContainer>
    )
}

const ListViewFilterPanel = () => {

    const { model, select, setStore } = useLocalStore(STORE)

    const fields = select('meta.fields') || []
    let filterFields = fields.filter((line: any) => line.in_standard_filter || line.reqd)
    filterFields.length = 3

    const filterFieldsMarkup = filterFields.map((line: any) => (
        <Input key={line.name} rounded={'md'} placeholder={line.label} size='sm' />
    ))

    return (
        <Flex p={2}>
            <HStack>
                <Input rounded={'md'} placeholder={'ID'} size='sm' />
                {filterFieldsMarkup}
            </HStack>
            <Spacer flex={1} />
            <ButtonGroup spacing='2'>
                <ButtonGroup variant='outline'>
                    <ButtonGroup size='sm' isAttached variant='outline'>
                        <Button leftIcon={<RiFilterLine />}>Filter</Button>
                        <IconButton aria-label='' icon={<RiFilterOffLine />} />
                    </ButtonGroup>
                </ButtonGroup>
                <ButtonGroup variant='outline'>
                    <ButtonGroup size='sm' isAttached variant='outline'>
                        <IconButton aria-label='Add to friends' icon={<RiSortAsc />} />
                        <Button>Last Updated On</Button>
                    </ButtonGroup>
                </ButtonGroup>
            </ButtonGroup>
        </Flex>
    )
}

const DataTable = ({ columns, data, onLinkClick }: any) => {

    const columnsMarkup = columns && columns.map((line: any) => {
        const isName = line.label == 'Name'
        const { fieldtype, label } = line

        const isNumaric = ['Currency', 'Percent'].includes(fieldtype)

        return (
            <Th key={label} isNumeric={isNumaric}>{label}</Th>
        )
    })

    const onNameClickHandler = (name: any) => {
        onLinkClick(name)
    }

    let dataMarkup = <></>

    if (data && data.values && Array.isArray(data.values))
        dataMarkup = data.values.map((line: any, index: number) => {
            return (
                <Tr key={`${line.name}-${index}`}>
                    {data.keys.map((key: any, lineIndex: number) => (
                        <Td
                            cursor={key == 'name' ? 'pointer' : ''}
                            key={`${line.name}-${key}-${lineIndex}`}
                            onClick={() => onNameClickHandler(line[0])}
                        >
                            {line[lineIndex]}
                        </Td>
                    ))}
                </Tr>
            )
        })

    return (
        <Box flex={1}>
            <TableContainer>
                <Table size="sm" minW="100%">
                    <Thead>
                        <Tr>
                            {columnsMarkup}
                            {/* <Th></Th> */}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {dataMarkup}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}