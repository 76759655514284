import {
    FormControl,
    FormLabel,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
} from '@chakra-ui/react';
import { useForm } from '@dladio/hooks';
import _ from 'lodash';

export const Float = ({ field }: any) => {

    const { form, setForm, formController, clearForm } = useForm()
    const ishidden = _.isString(field.depends_on)

    return (
        <FormControl hidden={ishidden}>
            <FormLabel fontSize={'sm'} fontWeight={'light'}>{field.label}</FormLabel>
            <NumberInput step={0.1}
                precision={2} size={'sm'}
                name={field.fieldname} onChange={formController} value={_.get(form, field.fieldname)}
            >
                <NumberInputField rounded={'md'} />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </FormControl>
    )
}