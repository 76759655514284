import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useForm } from "@dladio/hooks";
import _ from "lodash";

export const Date = ({ field }: any) => {

    const { form, setForm, formController, clearForm } = useForm()
    const ishidden = _.isString(field.depends_on)

    return (
        <FormControl>
            <FormLabel fontSize={'sm'} fontWeight={'light'}>{field.label}</FormLabel>
            <Input as="input" size={'sm'} rounded={'md'} type='date'
                name={field.fieldname} onChange={formController} value={_.get(form, field.fieldname)}
            />
        </FormControl>
    )
}