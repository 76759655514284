import { Box, chakra } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export const Logo = (props: any) => {

  const navigate = useNavigate()

  return (
    <Box
      onClick={() => navigate('/home')}
      cursor={'pointer'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        fontSize: 32,
        letterSpacing: '5px',
        fontWeight: 600,
      }}
    >
      <div>XCEED</div>
    </Box>
  )
}
