import { Box, Button, Flex, FormControl, FormLabel, Spacer, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useForm, useLocalStore } from "@dladio/hooks";
import _ from "lodash";

export const ChildTable = ({ field }: any) => {

    const { form, setForm, formController, clearForm } = useForm()
    const { model: documentMetaData } = useLocalStore(`doc-view-page.meta`)
    // const { select } = useLocalStore(`doc-view-page`)
    const ishidden = _.isString(field.depends_on)

    const docTypeMeta = documentMetaData
        .find((line: any) => line.name == field.options)

    const { in_create, allow_import, hide_toolbar } = docTypeMeta

    const header = docTypeMeta.fields
        .filter((line: any) => (line.reqd || line.in_list_view) && line.columns > 0)

    const data = _.get(form, field.fieldname)

    const headerMarkup = header && header.map((line: any, index: number) => (
        <Th key={index}>{line.label}</Th>
    ))

    const dataMarkup = data && data.length > 0 ? data.map((line: any, index: number) => (
        <Tr key={index}>
            {
                header.map((head: any, index: number) => (
                    <Td key={`${index}-${line.label}`}>{line[head.fieldname]}</Td>
                ))
            }
        </Tr>
    )) :
        (
            <Tr>
                <Td textAlign={'center'} colSpan={header.length}>No Data Found</Td>
            </Tr>
        )

    const markup = (
        <Flex direction={'column'} gap={2}>
            <FormControl>
                <FormLabel fontSize={'sm'} fontWeight={'light'}>{field.label}</FormLabel>
                <Box border={'1px'} rounded={'md'} borderColor={'gray.200'} maxW={'full'}>
                    <TableContainer>
                        <Table size="sm" overflow={'auto'}>
                            <Thead>
                                <Tr>
                                    {headerMarkup}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {dataMarkup}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </FormControl>
            <Flex justify={'space-between'}>
                <Flex gap={2}>
                    <Button colorScheme='gray' size='xs'>Add Multiple</Button>
                    <Button colorScheme='gray' size='xs'>Add Row</Button>
                </Flex>
                <Spacer />
                <Flex gap={2}>
                    <Button colorScheme='gray' size='xs'>Download</Button>
                    {allow_import ? (<Button colorScheme='gray' size='xs'>Upload</Button>) : null}
                </Flex>
            </Flex>
        </Flex>
    )

    return (
        <>
            {markup}
            {/* {(select('mode') == 'add' && in_create) || (select('mode') == 'edit') ? markup : null} */}
        </>
    );
};