import useLocalStore from "../../components/hooks/use-local-store-hook"
import { metaApi } from "../../services/MetaService"

export const STORE = 'general-list-view'

export const initGenetalTableAction = (doctype: string): any => {

    return async (dispatch: any) => {
        const [store, select, setStore] = useLocalStore(STORE)

        const { error, data, isSuccess } = await dispatch(metaApi.endpoints.getDocTypeMeta.initiate({
            doctype
        }))

        if (data && isSuccess) {

            const columns = data.docs[0].fields
                .filter((line: any) => line.in_list_view)

            // setStore(columns)
        }
    }
}