import {
    FormControl,
    FormLabel,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
} from '@chakra-ui/react';
import { useForm } from '@dladio/hooks';
import _ from 'lodash';

export const Color = ({ field }: any) => {

    const { form, setForm, formController, clearForm } = useForm()
    const ishidden = _.isString(field.depends_on)

    return (
        <FormControl hidden={ishidden}>
            <FormLabel fontSize={'sm'} fontWeight={'light'}>{field.label} -- TODO(Color)</FormLabel>
            <Input size={'sm'} rounded={'md'} type='email'
                name={field.fieldname} onChange={formController} value={_.get(form, field.fieldname) || ''}
            />
        </FormControl>
    )
}