import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  HStack,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useGetWorkspacesQuery } from '../../services/MetaService'
import { useLocalStore } from '@dladio/hooks'
import { S_WORKSPCE } from '../../consts'

type NavItemProps = {
  isActive?: boolean
  href?: string
  label?: string
}

const DesktopNavItem = (props: any) => {
  const { label, ...page } = props

  const navigate = useNavigate()
  const { model: store, setStore, select } = useLocalStore(S_WORKSPCE)

  const isActive = select('current.name') == page.name

  const onClick = () => {
    setStore('current', page)
  }

  return (
    <Flex
      as="a"
      onClick={onClick}
      direction="column"
      justify="center"
      minH="10"
      fontSize="sm"
      fontWeight="medium"
      position="relative"
      cursor={'pointer'}
      aria-current={isActive ? 'page' : undefined}
      color={mode('gray.600', 'gray.400')}
      _activeLink={{
        borderBottomWidth: '2px',
        borderColor: 'currentColor',
        color: mode('teal.500', 'teal.300'),
      }}
      _hover={{
        color: mode('teal.500', 'teal.300'),
      }}
      {...page}
    >

      <Text whiteSpace="nowrap">
        {label}
      </Text>

    </Flex>
  )
}

const MobileNavItem = (props: BoxProps & NavItemProps) => {
  const { label, href, isActive, ...rest } = props
  return (
    <Box
      as="a"
      href={href}
      aria-current={isActive ? 'page' : undefined}
      py="2"
      px="3"
      _activeLink={{
        bg: mode('white', 'gray.600'),
        shadow: 'base',
      }}
      {...rest}
    >
      {label}
    </Box>
  )
}

const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
}

const menus = [
  { label: 'Courses', href: '/pos' },
  { label: 'Students', href: '#' },
  { label: 'Documents', href: '#' },
  { label: 'Resources', href: '#' },
]

const DesktopNavCategoryMenu = () => {

  const { model: store, setStore } = useLocalStore(S_WORKSPCE)
  const { data, isLoading } = useGetWorkspacesQuery({})

  const workspacesMarkup = data?.message.pages.map((page: any) => (
    <NavItem.Desktop key={page.label} {...page} />
  ))

  return (
    <Box
      borderTopWidth="1px"
      borderBottomWidth="1px"
      borderColor={mode('gray.200', 'gray.700')}
      bg={mode('white', 'gray.800')}
      px="8"
      overflow={'auto'}
    >
      <Box mx="auto">
        <HStack spacing="8">
          {workspacesMarkup}
        </HStack>
      </Box>
    </Box>
  )
}

const MobileNavCategoryMenu = () => {
  return (
    <Box
      width="32%"
      minW="100px"
      bg={mode('gray.50', 'gray.700')}
      color={mode('gray.600', 'gray.100')}
      borderEndWidth="1px"
      py="6"
    >
      <Stack spacing="1">
        {menus.map((link) => (
          <NavItem.Mobile key={link.label} {...link} isActive={link.label === 'Men'} />
        ))}
      </Stack>
    </Box>
  )
}

export const NavCategoryMenu = {
  Mobile: MobileNavCategoryMenu,
  Desktop: DesktopNavCategoryMenu,
}
