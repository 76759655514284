import { Box, Card, CardBody, Grid, GridItem } from "@chakra-ui/react"

export const AllItemSelector = () => {

    return (
        <Card overflow={'auto'} variant={'filled'} className="w-1/3">
            <CardBody flex={1}>
                <Box minH={'full'} maxH={'full'} overflow={'auto'}>
                    <Grid className="max-h-1" templateColumns='repeat(2, 1fr)' gap={4}>
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                        <GridItem aspectRatio={1} bg='teal.500' rounded={'lg'} />
                    </Grid>
                </Box>
            </CardBody>
        </Card>
    )
}