import { chakra } from '@chakra-ui/react'

export const Logo = (props: any) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      fontSize: 32,
      letterSpacing: '5px',
      fontWeight: 600,
    }}
  >
    <div>XPOS</div>
  </div>

)
