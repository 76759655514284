import { Outlet } from "react-router-dom"
import { RootUi } from "./RootUi"
import { Box, Flex, HStack } from "@chakra-ui/react"

export const PosLayout = () => {

    return (
        <RootUi>
            <Body>
                <Outlet />
            </Body>
        </RootUi>
    )
}

const Body = ({ children }: any) => {

    return (
        <Box bg={'gray.100'} flex={1}>
            {children}
        </Box>
    )
}

