import { Box, Card, CardBody, Divider, Flex, FormControl, FormLabel, Grid, GridItem, Image, Input, Stat, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/react"


export const ItemEditor = () => {

    return (
        <Card variant={'outline'} className="w-2/3 min-h-full">
            <CardBody>
                <Flex>
                    <Box flex={1}>
                        <Stat>
                            <StatLabel>Petrol 92</StatLabel>
                            <StatNumber>Rs 0.00</StatNumber>
                            <StatHelpText>Petrol 92</StatHelpText>
                        </Stat>
                    </Box>
                    <Box>
                        <Image rounded={'lg'} boxSize='150px' src='https://bit.ly/dan-abramov' alt='Dan Abramov' />
                    </Box>
                </Flex>
                <Divider orientation='horizontal' my={4} />
                <Box>
                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Quantity</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>UOM</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Rate</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>UOM Conversion Factor</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Discount (%)</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Warehouse</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Available Qty at Warehouse</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>Price List Rate</FormLabel>
                                <Input variant={'outline'} type='email' />
                            </FormControl>
                        </GridItem>
                    </Grid>
                </Box>
            </CardBody>
        </Card>
    )
}