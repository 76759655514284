import { TabPanel as CTabPanel, Flex } from "@chakra-ui/react"
import { useLocalStore } from "@dladio/hooks"
import { SectionedViewLayout } from "./SectionedViewLayout"

export const TabPanel = ({ line }: any) => {

    const { select } = useLocalStore('doc-view-page')

    // const fieldMarkup = select('tab_fields') && select('tab_fields')[line.fieldname].map((field: any, index: number) => (
    //     <FormElementGenerator key={field.fieldname} field={field} />
    // ))

    return (
        <CTabPanel p={0}>
            <SectionedViewLayout meta={select('tab_fields')[line.fieldname]} />
        </CTabPanel>
    )
}

const Section = () => {

    return (
        <></>
    )
}