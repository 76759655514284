import { Badge, Text } from "@chakra-ui/react"
import { useForm, useLocalStore } from "@dladio/hooks"

export const ViewPageFormStatus = () => {

    const { select } = useLocalStore('doc-view-page')
    const { form } = useForm()

    const status = 'dirty'

    const statuses = {
        'dirty': ['green', 'Not Saved'],
        'invalid': ['red', 'Invalid Form']
    }

    return (
        <>
            {form ? (
                <Badge variant='outline' colorScheme={statuses[status][0]}>
                    {statuses[status][1]}
                </Badge>
            ): null}
        </>
    )
}