import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../services/UserService";
import { forwardRef, useEffect, useRef, useState } from "react";
import { chakra, Box, Button, Center, Checkbox, Container, Divider, Flex, FormControl, FormHelperText, FormLabel, HStack, HTMLChakraProps, Heading, IconButton, Input, InputGroup, InputProps, InputRightElement, Spacer, Stack, Text, VStack, useDisclosure, useMergeRefs } from "@chakra-ui/react";
import { useForm } from "@dladio/hooks";
import { HiEye, HiEyeOff } from 'react-icons/hi'
import _ from "lodash";
import { LoginIllustration } from "../components/general/LoginIllustration";

export const LoginPage = () => {
    const navigate = useNavigate();

    const [login, { isLoading, isSuccess, data }] = useLoginMutation()
    const { form, setForm, formController, clearForm } = useForm('login')

    const loginActionHandler = () => {
        login({ 'usr': 'coop@dlad.io', pwd: 'coop@1234' })
        // login({ 'usr': 'Administrator', pwd: 'Come@#0ne' })
    }

    useEffect(() => {
        if (isSuccess) {
            clearForm()
            navigate('/home')
        }
    }, [isLoading])

    return (
        <Flex minH={'100vh'} className="bg-gradient-to-r from-teal-200 to-lime-200">
            <Flex direction={'column'} minH={'full'} flex={1} pl={'10'}>
                {/* <Box pt={10}><Heading color={'black'}>XCEED</Heading></Box> */}
                <Flex flex={1} align={'center'}>
                    <LoginIllustration height={{ sm: '24rem', lg: '28rem' }} />
                </Flex>
                <Flex pb={10} justify={'center'}>
                    <Text color={'gray.900'}>
                        © 2023 DLAD Software Solutions. All rights reserved.
                    </Text>
                </Flex>
            </Flex>
            <Box flex={1}>
                <Center minH={'full'}>
                    <Container bg={'white'} rounded={'lg'} maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                        <Stack spacing="8">
                            <Stack spacing="6">
                                <Center>
                                    <Logo />
                                </Center>
                                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                                    <Heading size={{ base: 'xs', md: 'sm' }}>Log in to <span className="tracking-wider">Roots</span></Heading>

                                </Stack>
                            </Stack>
                            <Box
                                py={{ base: '0', sm: '8' }}
                                px={{ base: '4', sm: '10' }}
                                bg={{ base: 'transparent', sm: 'bg.surface' }}
                            >
                                <Stack spacing="6">
                                    <Stack spacing="5">
                                        <FormControl>
                                            <FormLabel htmlFor="email">Email</FormLabel>
                                            <Input value={_.get(form, 'usr')} onChange={formController} name="usr" id="email" type="email" />
                                        </FormControl>
                                        <PasswordField value={_.get(form, 'pwd')} onChange={formController} name="pwd" />
                                    </Stack>
                                    <HStack justify="space-between">
                                        <Checkbox defaultChecked>Remember me</Checkbox>
                                        <Button variant="text" size="sm">
                                            Forgot password?
                                        </Button>
                                    </HStack>
                                    <Stack spacing="6">
                                        <Button isLoading={isLoading}
                                            loadingText='We are signing you in' variant="outline" onClick={loginActionHandler}>Log in</Button>
                                    </Stack>
                                </Stack>

                            </Box>
                        </Stack>
                        <HStack spacing="1" justify="center">
                            <Text color="fg.muted">Don't have an account?</Text>
                            <Button variant="text" size="sm">
                                Please Contact Administrator
                            </Button>
                        </HStack>
                    </Container>
                </Center>
            </Box>
        </Flex>
    )
}

const PasswordField = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = useRef<HTMLInputElement>(null)

    const mergeRef = useMergeRefs(inputRef, ref)
    const onClickReveal = () => {
        onToggle()
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true })
        }
    }

    return (
        <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <InputGroup>
                <InputRightElement>
                    <IconButton
                        variant="text"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                    />
                </InputRightElement>
                <Input
                    id="password"
                    ref={mergeRef}
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="current-password"
                    required
                    {...props}
                />
            </InputGroup>
        </FormControl>
    )
})

PasswordField.displayName = 'PasswordField'

const Logo = (props: HTMLChakraProps<'svg'>) => (
    <chakra.svg
        version="1.0" width="70.000000pt" height="70.000000pt" viewBox="0 0 842.000000 842.000000" preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <g transform="translate(0.000000,842.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M4025 8068 c-215 -217 -349 -412 -452 -658 -266 -637 -157 -1328 298 -1890 79 -97 245 -268 253 -259 2 2 -6 36 -19 74 -59 178 -79 358 -72 655 9 372 76 710 189 955 l23 50 6 -400 c7 -422 15 -535 54 -731 37 -183 31 -177 96 -102 291 335 439 709 439 1111 0 379 -111 698 -353 1022 -64 85 -289 315 -308 315 -7 0 -76 -64 -154 -142z" />
            <path d="M6390 7454 c-672 -86 -1224 -463 -1495 -1019 -148 -305 -213 -651 -186 -988 11 -134 33 -285 47 -327 3 -8 18 25 35 73 130 389 539 894 943 1166 74 50 136 85 136 77 0 -3 -79 -116 -175 -251 -264 -371 -324 -463 -405 -625 -45 -91 -93 -210 -87 -217 8 -7 187 38 292 74 417 144 743 428 937 815 168 335 213 769 123 1166 -19 83 -3 77 -165 56z" />
            <path d="M1816 7357 c-38 -171 -49 -283 -50 -482 0 -149 4 -228 18 -310 138 -834 767 -1426 1646 -1550 52 -7 96 -12 98 -10 2 1 -28 24 -65 51 -151 106 -374 362 -518 594 -84 135 -185 336 -240 473 -41 104 -111 345 -102 353 2 2 98 -123 213 -278 250 -337 326 -429 472 -570 127 -122 149 -138 160 -111 4 10 7 117 7 238 -1 166 -5 242 -18 312 -66 346 -222 643 -463 879 -273 267 -654 434 -1091 478 l-51 5 -16 -72z" />
            <path d="M6752 5809 c-643 -56 -1200 -449 -1512 -1068 -69 -139 -70 -138 23 -72 274 196 744 344 1207 381 133 11 337 7 385 -7 13 -4 -85 -41 -275 -104 -162 -55 -340 -115 -395 -135 -132 -48 -345 -150 -436 -209 l-74 -48 70 -33 c95 -45 269 -100 390 -124 133 -27 369 -36 505 -21 491 58 921 337 1204 781 50 79 152 282 158 314 3 21 -34 46 -190 127 -323 167 -718 248 -1060 218z" />
            <path d="M1150 5696 c-216 -33 -362 -81 -578 -187 -90 -45 -165 -86 -168 -93 -13 -33 138 -320 256 -489 88 -126 317 -355 448 -447 487 -344 1099 -422 1692 -215 105 37 291 123 363 168 l38 23 -59 -4 c-73 -5 -234 12 -357 39 -391 84 -863 307 -1130 536 -42 36 -75 67 -73 69 2 2 47 -10 99 -27 281 -90 645 -199 724 -217 147 -34 425 -68 425 -52 0 4 -23 44 -50 90 -251 417 -637 696 -1095 791 -127 27 -409 34 -535 15z" />
            <path d="M6130 4264 c-14 -2 -65 -9 -115 -15 -197 -25 -431 -100 -640 -205 -153 -77 -168 -88 -104 -79 58 9 246 -10 369 -37 351 -75 756 -255 1030 -456 93 -69 182 -143 176 -149 -2 -2 -120 33 -262 77 -386 122 -409 129 -539 160 -197 47 -445 77 -445 53 0 -20 144 -234 215 -320 232 -281 579 -488 935 -558 116 -23 372 -31 496 -16 241 30 475 108 687 229 112 65 109 45 33 207 -329 695 -982 1122 -1701 1114 -60 -1 -121 -3 -135 -5z" />
            <path d="M1750 4045 c-476 -65 -901 -350 -1172 -787 -51 -82 -158 -294 -158 -313 0 -21 248 -151 400 -208 426 -162 864 -177 1269 -41 400 133 745 407 983 779 86 135 195 351 166 328 -288 -227 -750 -387 -1253 -433 -165 -15 -415 -9 -415 10 0 3 138 53 308 110 364 122 500 174 644 246 109 55 212 116 222 133 6 10 -100 57 -219 98 -244 83 -527 112 -775 78z" />
            <path d="M4910 3401 c74 -49 152 -114 225 -187 254 -253 509 -672 625 -1024 35 -105 72 -250 65 -250 -2 0 -94 123 -206 273 -111 149 -242 319 -290 377 -127 153 -329 343 -347 326 -4 -4 -7 -116 -7 -249 0 -213 3 -255 23 -349 157 -747 727 -1237 1538 -1323 l60 -7 17 79 c83 386 68 745 -44 1087 -132 403 -401 743 -764 966 -157 97 -368 185 -555 234 -81 20 -141 31 -340 60 l-25 4 25 -17z" />
            <path d="M3655 3284 c-8 -47 -55 -164 -101 -256 -144 -286 -439 -642 -714 -861 -91 -73 -269 -191 -276 -184 -3 3 90 140 206 304 203 285 303 440 369 572 35 67 93 212 89 217 -12 11 -270 -60 -373 -103 -535 -223 -885 -643 -998 -1197 -19 -96 -22 -142 -22 -341 0 -227 8 -305 46 -448 l10 -39 62 7 c288 30 541 101 772 218 666 335 1048 1024 994 1790 -9 123 -41 342 -52 354 -3 2 -8 -13 -12 -33z" />
            <path d="M4327 3071 c53 -164 68 -283 68 -561 0 -314 -29 -547 -101 -795 -25 -89 -90 -263 -104 -278 -4 -4 -10 169 -14 385 -6 389 -20 579 -51 726 -24 117 -37 162 -45 162 -14 0 -147 -164 -213 -263 -246 -367 -336 -808 -251 -1227 52 -255 184 -525 363 -744 67 -81 255 -266 270 -266 16 0 225 205 296 290 626 754 600 1748 -64 2488 -44 49 -104 109 -133 134 l-53 45 32 -96z" />
        </g>
    </chakra.svg>
)