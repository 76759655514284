import { createBrowserRouter } from "react-router-dom";
import { ExternalLayout } from "../components/layouts/ExternalLayout";
import { LoginPage } from "../pages/LoginPage";
import { InternalLayout } from "../components/layouts/InternalLayout";
import { HomePage } from "../pages/HomePage";
import { GeneralListPage } from "../pages/GeneralListPage";
import { GeneralViewPage } from "../pages/GeneralViewPage";
import { PointOfSales } from "../pages/PointOfSales";
import { PosLayout } from "../components/layouts/PosLayout";
import { SampleListPage } from "../pages/SampleListPage";
import { SampleViewPage } from "../pages/SampleViewPage";
import { InternalPageLayout } from "../components/layouts/InternalPageLayout";
import { SampleAddPage } from "../pages/SampleAddPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <ExternalLayout />,
        children: [
            {
                index: true,
                element: <LoginPage />
            }
        ]
    },
    {
        path: "/home",
        element: <InternalLayout />,
        children: [
            {
                index: true,
                element: <HomePage />
            },
            {
                path: 'pos',
                element: <PointOfSales />
            }
        ]
    },
    {
        path: "/pos",
        element: <PosLayout />,
        children: [
            {
                index: true,
                element: <PointOfSales />
            }
        ]
    },
    {
        path: "/:doctype",
        element: <InternalPageLayout />,
        children: [
            {
                index: true,
                element: <SampleListPage />
            },
            {
                path: '/:doctype/new',
                element: <SampleAddPage />
            },
            {
                path: '/:doctype/:name',
                element: <SampleViewPage />
            }
        ]
    },

]);

export default router